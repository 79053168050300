import { PRODUCTION_SERVER_URL, TEST_PRODUCTION_SERVER_URL } from "../../constants";

export const ALL_APPS = "all_apps";

export const INSPIRING_APP = "inspiring";
export const SHOUTOUT_APP = "shoutout";
export const CR_APP = "cr";
export const LEADGEN = "leadgen";
export const BOLDJOURNEY_APP = "boldjourney";
export const CITYNATIONAL_APP = "citynational";
export const ADMIN = "admin";
export const SERVER_URL = "http://localhost:5000";

export const CREATE_SERVER_URL = process.env.NODE_ENV === "production" ? PRODUCTION_SERVER_URL : "http://localhost:3000";

export const getServerUrl = () => {
  let serverUrl = SERVER_URL;
  if (process.env.NODE_ENV === "production") {
    serverUrl = PRODUCTION_SERVER_URL;
  } else if (process.env.NODE_ENV === "test_production") {
    serverUrl = TEST_PRODUCTION_SERVER_URL;
  }

  return serverUrl;
};

export const getCreateServerUrl = () => {
  return window.location.origin;
};

export const APPS = [INSPIRING_APP, SHOUTOUT_APP, CR_APP, BOLDJOURNEY_APP, CITYNATIONAL_APP];

export const APPS_MAP = {
  [INSPIRING_APP]: "Inspiring",
  [SHOUTOUT_APP]: "Shoutout",
  [CR_APP]: "CR",
  [BOLDJOURNEY_APP]: "Bold Journey",
  [CITYNATIONAL_APP]: "CR2V",
};

export const STATES = {
  AL: 'AL',
  AK: 'AK',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NY: 'NY',
  NC: 'NC',
  ND: 'ND',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  PR: "PR",
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',
  OT: "OT"
};

export const STATES_MAP = {
  [STATES.AL]: 'Alabama',
  [STATES.AK]: 'Alaska',
  [STATES.AZ]: 'Arizona',
  [STATES.AR]: 'Arkansas',
  [STATES.CA]: 'California',
  [STATES.CO]: 'Colorado',
  [STATES.CT]: 'Connecticut',
  [STATES.DE]: 'Delaware',
  [STATES.FL]: 'Florida',
  [STATES.GA]: 'Georgia',
  [STATES.HI]: 'Hawaii',
  [STATES.ID]: 'Idaho',
  [STATES.IL]: 'Illinois',
  [STATES.IN]: 'Indiana',
  [STATES.IA]: 'Iowa',
  [STATES.KS]: 'Kansas',
  [STATES.KY]: 'Kentucky',
  [STATES.LA]: 'Louisiana',
  [STATES.ME]: 'Maine',
  [STATES.MD]: 'Maryland',
  [STATES.MA]: 'Massachusetts',
  [STATES.MI]: 'Michigan',
  [STATES.MN]: 'Minnesota',
  [STATES.MS]: 'Mississippi',
  [STATES.MO]: 'Missouri',
  [STATES.MT]: 'Montana',
  [STATES.NE]: 'Nebraska',
  [STATES.NV]: 'Nevada',
  [STATES.NH]: 'New Hampshire',
  [STATES.NJ]: 'New Jersey',
  [STATES.NM]: 'New Mexico',
  [STATES.NY]: 'New York',
  [STATES.NC]: 'North Carolina',
  [STATES.ND]: 'North Dakota',
  [STATES.OH]: 'Ohio',
  [STATES.OK]: 'Oklahoma',
  [STATES.OR]: 'Oregon',
  [STATES.PA]: 'Pennsylvania',
  [STATES.PR]: 'Puerto Rico',
  [STATES.RI]: 'Rhode Island',
  [STATES.SC]: 'South Carolina',
  [STATES.SD]: 'South Dakota',
  [STATES.TN]: 'Tennessee',
  [STATES.TX]: 'Texas',
  [STATES.UT]: 'Utah',
  [STATES.VT]: 'Vermont',
  [STATES.VA]: 'Virginia',
  [STATES.WA]: 'Washington',
  [STATES.WV]: 'West Virginia',
  [STATES.WI]: 'Wisconsin',
  [STATES.WY]: 'Wyoming',
  [STATES.OT]: 'Other'
};

export const ALL_CITIES = "All Cities";

export const CITIES = {
  ATL: { name: 'atl', state: STATES.GA },
  AUSTIN: { name: 'austin', state: STATES.TX },
  BALTIMORE: { name: 'baltimore', state: STATES.MD },
  DALLAS: { name: 'dallas', state: STATES.TX },
  DENVER: { name: 'denver', state: STATES.CO },
  HOUSTON: { name: 'houston', state: STATES.TX },
  INDIANA: { name: 'indiana', state: STATES.IN },
  JACKSONVILLE: { name: 'jacksonville', state: STATES.FL },
  KC: { name: 'kc', state: STATES.MO },
  KNOXVILLE: { name: 'knoxville', state: STATES.TN },
  LA: { name: 'la', state: STATES.CA },
  MIAMI: { name: 'miami', state: STATES.FL },
  MICHIGAN: { name: 'michigan', state: STATES.MI },
  MINNESOTA: { name: 'minnesota', state: STATES.MN },
  NASHVILLE: { name: 'nashville', state: STATES.TN },
  NORTHCAROLINA: { name: 'northcarolina', state: STATES.NC },
  OHIO: { name: 'ohio', state: STATES.OH },
  ORLANDO: { name: 'orlando', state: STATES.FL },
  PHOENIX: { name: 'phoenix', state: STATES.AZ },
  RALEIGH: { name: 'raleigh', state: STATES.NC },
  SANANTONIO: { name: 'sanantonio', state: STATES.TX },
  SAVANNAH: { name: 'savannah', state: STATES.GA },
  SD: { name: 'sd', state: STATES.CA },
  SOUTHCAROLINA: { name: 'southcarolina', state: STATES.SC },
  STL: { name: 'stl', state: STATES.MO },
  TAMPA: { name: 'tampa', state: STATES.FL },
  TENNESSEE: { name: 'tennessee', state: STATES.TN },
  UTAH: { name: 'utah', state: STATES.UT },
  MEMPHIS: { name: 'Memphis', state: STATES.TN },
};

export const NATIONAL_CITIES = {
  ATL: { name: 'Atlanta', state: STATES.GA },
  AUSTIN: { name: 'Austin', state: STATES.TX },
  DALLAS: { name: 'Dallas Fort Worth', state: STATES.TX },
  HOUSTON: { name: 'Houston', state: STATES.TX },
  JACKSONVILLE: { name: 'Jacksonville', state: STATES.FL },
  KNOXVILLE: { name: 'Knoxville', state: STATES.TN },
  LA: { name: 'Los Angeles', state: STATES.CA },
  MEMPHIS: { name: 'Memphis', state: STATES.TN },
  MIAMI: { name: 'Miami', state: STATES.FL },
  NASHVILLE: { name: 'Nashville', state: STATES.TN },
  OC: { name: 'Orange County', state: STATES.CA },
  ORLANDO: { name: 'Orlando', state: STATES.FL },
  SANANTONIO: { name: 'San Antonio', state: STATES.TX },
  SAVANNAH: { name: 'Savannah', state: STATES.GA },
  SD: { name: 'San Diego', state: STATES.CA },
  SF: { name: 'San Francisco', state: STATES.CA },
  SoFL: { name: 'South Florida', state: STATES.FL },
  MA: { name: 'Marin', state: STATES.CA },
  TAMPA: { name: 'Tampa', state: STATES.FL },
  CAOT: { name: 'Other', state: STATES.CA },
  FLOT: { name: 'Other', state: STATES.FL },
  GOT: { name: 'Other', state: STATES.GA },
  TOT: { name: 'Other', state: STATES.TN },
  TXOT: { name: 'Other', state: STATES.TX },
};

export const INSPIRING_CITIES = [
  CITIES.AUSTIN,
  CITIES.ATL,
  CITIES.BALTIMORE,
  CITIES.DALLAS,
  CITIES.DENVER,
  CITIES.HOUSTON,
  CITIES.INDIANA,
  CITIES.JACKSONVILLE,
  CITIES.KC,
  CITIES.KNOXVILLE,
  CITIES.LA,
  CITIES.MEMPHIS,
  CITIES.MIAMI,
  CITIES.MICHIGAN,
  CITIES.MINNESOTA,
  CITIES.NASHVILLE,
  CITIES.OHIO,
  CITIES.ORLANDO,
  CITIES.PHOENIX,
  CITIES.RALEIGH,
  CITIES.SANANTONIO,
  CITIES.SAVANNAH,
  CITIES.SD,
  CITIES.SOUTHCAROLINA,
  CITIES.STL,
  CITIES.TAMPA,
  CITIES.UTAH,
];

export const SHOUTOUT_CITIES = [
  CITIES.ATL,
  CITIES.DALLAS,
  CITIES.DENVER,
  CITIES.HOUSTON,
  CITIES.LA,
  CITIES.MIAMI,
  CITIES.MICHIGAN,
  CITIES.NORTHCAROLINA,
  CITIES.OHIO,
  CITIES.PHOENIX,
  CITIES.SD,
  CITIES.SOUTHCAROLINA,
  CITIES.TENNESSEE,
];

export const INSPIRING_INBOX_LIST = {
  "sid@voyagela.com": "Sid",
  "stephanie@voyagela.com": "Stephanie",
  "mike@voyagela.com": "Mike",
  "kris@voyagela.com": "Kris",
  "sarah@voyagela.com": "Sarah",
  "alex@voyagela.com": "Alex",
  "chris@voyagela.com": "Chris",
  "jess@voyagela.com": "Jess",
  "ravinah@voyagela.com": "Ravinah",
  "andrea@voyagela.com": "Andrea",
  "samantha@voyagela.com": "Samantha",
  "jasmine@voyagela.com": "Jasmine",
  "pat@sdvoyager.com": "Pat",
  "stephanie@sdvoyager.com": "Stephanie",
  "emily@sdvoyager.com": "Emily",
  "mary@sdvoyager.com": "Mary",
  "jessica@sdvoyager.com": "Jessica",
  "eric@sdvoyager.com": "Eric",
  "sarah@sdvoyager.com": "Sarah",
  "ravinah@voyagemia.com": "Ravinah",
  "erika@voyagemia.com": "Erika",
  "dan@voyagemia.com": "Dan",
  "emma@voyagemia.com": "Emma",
  "jessica@voyagemia.com": "jessica",
  "sarah@voyagemia.com": "Sarah",
  "david@voyagemia.com": "David",
  "angela@voyagemia.com": "Angela",
  "kenneth@voyageatl.com": "Kenneth",
  "mike@voyageatl.com": "Mike",
  "shelly@voyageatl.com": "Shelley",
  "dianne@voyageatl.com": "Dianne",
  "sofia@voyageatl.com": "Sofia",
  "erika@voyageatl.com": "Erika",
  "olivia@voyageatl.com": "Olivia",
  "stephanie@voyageatl.com": "Stephanie",
  "sarah@voyageatl.com": "Sarah",
  "david@voyageatl.com": "David",
  "jessica@voyageatl.com": "Jessica",
  "andrea@voyageatl.com": "Andrea",
  "angela@voyagehouston.com": "Angela",
  "iris@voyagehouston.com": "Iris",
  "michelle@voyagehouston.com": "Michelle",
  "leo@voyagehouston.com": "Leo",
  "eric@voyagehouston.com": "Eric",
  "james@voyagehouston.com": "James",
  "jonathan@voyagedallas.com": "Jonathan",
  "kristen@voyagedallas.com": "Kristen",
  "charles@voyagedallas.com": "Charles",
  "christian@voyagedallas.com": "Christian",
  "camila@voyagedallas.com": "Camila",
  "emily@voyagedallas.com": "Emily",
  "david@voyagedallas.com": "David",
  "erica@voyagedallas.com": "Erica",
  "michael@voyagedallas.com": "Michael",
  "jon@voyagephoenix.com": "Jon",
  "nick@voyagephoenix.com": "Nick",
  "alex@voyagephoenix.com": "Alex",
  "rob@voyagephoenix.com": "Rob",
  "megan@voyagephoenix.com": "Megan",
  "eric@voyagephoenix.com": "Eric",
  "sarah@voyagephoenix.com": "Sarah",
  "nick@voyagedenver.com": "Nick",
  "sarah@voyagedenver.com": "Sarah",
  "kim@voyagedenver.com": "Kim",
  "eric@voyagedenver.com": "Eric",
  "stephanie@voyageaustin.com": "Stephanie",
  "david@voyageaustin.com": "David",
  "stephanie@orlandovoyager.com": "Stephanie",
  "david@orlandovoyager.com": "David",
  "stephanie@voyagetampa.com": "Stephanie",
  "david@voyagetampa.com": "David",
  "stephanie@voyagekc.com": "Stephanie",
  "sarah@voyagekc.com": "Sarah",
  "stephanie@nashvillevoyage.com": "Stephanie",
  "sarah@nashvillevoyager.com": "Sarah",
  "stephanie@voyagestl.com": "Stephanie",
  "stephanie@voyagebaltimore.com": "Stephanie",
  "camila@voyagebaltimore.com": "Camila",
  "stephanie@voyageraleigh.com": "Stephanie",
  "camila@voyagesanantonio.com": "Camilla",
  "jessica@voyagesavannah.com": "Jessica",
  "camila@voyagejacksonville.com": "Camilla",
  "jessica@voyagemichigan.com": "Jessica",
  "david@voyagemichigan.com": "David",
  "erica@voyagemichigan.com": "Erica",
  "jessica@memphisvoyager.com": "Jessica",
  "jessica@voyageUtah.com": "Jessica",
  "camila@knoxvillevoyager.com": "Camilla",
  "camila@voyageminnesota.com": "Camilla",
  "camila@southcarolinavoyager.com": "Camilla",
  "jessica@voyageohio.com": "Jessica",
  "sarah@voyageohio.com": "Sarah",
  "david@voyageohio.com": "David",
  "ashley@voyageohio.com": "Ashley",
};

export const INSPIRING_INBOX_SANANTONIO = ["camila@voyagesanantonio.com"];

export const INSPIRING_INBOX_SAVANNAH = ["jessica@voyagesavannah.com"];

export const INSPIRING_INBOX_JACKSONVILLE = ["camila@voyagejacksonville.com"];

export const INSPIRING_INBOX_MICHIGAN = ["david@voyagemichigan.com", "erica@voyagemichigan.com", "jessica@voyagemichigan.com"];

export const INSPIRING_INBOX_OHIO = ["ashley@voyageohio.com", "david@voyageohio.com", "jessica@voyageohio.com", "sarah@voyageohio.com"];

export const INSPIRING_INBOX_MEMPHIS = ["jessica@memphisvoyager.com"];

export const INSPIRING_INBOX_UTAH = ["jessica@voyageutah.com"];

export const INSPIRING_INBOX_SOUTHCAROLINA = ["camila@southcarolinavoyager.com"];

export const INSPIRING_INBOX_KNOXVILLE = ["camila@knoxvillevoyager.com"];

export const INSPIRING_INBOX_MINNESOTA = ["camila@voyageminnesota.com"];

export const INSPIRING_INBOX_LA = [
  "andrea@voyagela.com",
  "chris@voyagela.com",
  "jess@voyagela.com",
  "jasmine@voyagela.com",
  "kris@voyagela.com",
  "michael@voyagedallas.com",
  "mike@voyagela.com",
  "ravinah@voyagela.com",
  "sarah@voyagela.com",
  "samantha@voyagela.com",
  "sid@voyagela.com",
  "stephanie@voyagela.com",
];

export const INSPIRING_INBOX_SD = [
  "emily@sdvoyager.com",
  "eric@sdvoyager.com",
  "jessica@sdvoyager.com",
  "mary@sdvoyager.com",
  "pat@sdvoyager.com",
  "sarah@sdvoyager.com",
  "stephanie@sdvoyager.com",
];

export const INSPIRING_INBOX_MIAMI = [
  "angela@voyagemia.com",
  "dan@voyagemia.com",
  "david@voyagemia.com",
  "emma@voyagemia.com",
  "erika@voyagemia.com",
  "jessica@voyagemia.com",
  "ravinah@voyagemia.com",
  "sarah@voyagemia.com",
];

export const INSPIRING_INBOX_ATLANTA = [
  "andrea@voyageatl.com",
  "david@voyageatl.com",
  "dianne@voyageatl.com",
  "erika@voyageatl.com",
  "jessica@voyageatl.com",
  "kenneth@voyageatl.com",
  "mike@voyageatl.com",
  "olivia@voyageatl.com",
  "sarah@voyageatl.com",
  "sofia@voyagatl.com",
  "shelly@voyageatl.com",
  "stephanie@voyageatl.com",
];

export const INSPIRING_INBOX_HOUSTON = [
  "angela@voyagehouston.com",
  "eric@voyagehouston.com",
  "james@voyagehouston.com",
  "iris@voyagehouston.com",
  "leo@voyagehouston.com",
  "michelle@voyagehouston.com",
];

export const INSPIRING_INBOX_DALLAS = [
  "camila@voyagedallas.com",
  "charles@voyagedallas.com",
  "christian@voyagedallas.com",
  "david@voyagedallas.com",
  "emily@voyagedallas.com",
  "erica@voyagedallas.com",
  "jonathan@voyagedallas.com",
  "kristen@voyagedallas.com",
  "michael@voyagedallas.com",
];

export const INSPIRING_INBOX_PHOENIX = [
  "alex@voyagephoenix.com",
  "eric@voyagephoenix.com",
  "jon@voyagephoenix.com",
  "megan@voyagephoenix.com",
  "nick@voyagephoenix.com",
  "rob@voyagephoenix.com",
  "sarah@voyagephoenix.com",
];

export const INSPIRING_INBOX_TAMPA = ["david@voyagetampa.com", "stephanie@voyagetampa.com"];

export const INSPIRING_INBOX_ORLANDO = ["david@orlandovoyager.com", "stephanie@orlandovoyager.com"];

export const INSPIRING_INBOX_AUSTIN = ["david@voyageaustin.com", "stephanie@voyageaustin.com"];

export const INSPIRING_INBOX_NASHVILLE = ["sarah@nashvillevoyager.com", "stephanie@nashvillevoyage.com"];

export const INSPIRING_INBOX_RALEIGH = ["stephanie@voyageraleigh.com"];

export const INSPIRING_INBOX_STL = ["stephanie@voyagestl.com"];

export const INSPIRING_INBOX_KC = ["sarah@voyagekc.com", "stephanie@voyagekc.com"];

export const INSPIRING_INBOX_BALTIMORE = ["camila@voyagebaltimore.com", "stephanie@voyagebaltimore.com"];

export const INSPIRING_INBOX_DENVER = ["eric@voyagedenver.com", "kim@voyagedenver.com", "nick@voyagedenver.com", "sarah@voyagedenver.com"];

export const INSPIRING_INBOX_INDIANA = ["stephanie@voyageindiana.com"];

export const INSPIRING_CITY_INBOX_MAP = {
  la: INSPIRING_INBOX_LA,
  sd: INSPIRING_INBOX_SD,
  atl: INSPIRING_INBOX_ATLANTA,
  dallas: INSPIRING_INBOX_DALLAS,
  houston: INSPIRING_INBOX_HOUSTON,
  miami: INSPIRING_INBOX_MIAMI,
  phoenix: INSPIRING_INBOX_PHOENIX,
  denver: INSPIRING_INBOX_DENVER,
  austin: INSPIRING_INBOX_AUSTIN,
  orlando: INSPIRING_INBOX_ORLANDO,
  tampa: INSPIRING_INBOX_TAMPA,
  kc: INSPIRING_INBOX_KC,
  nashville: INSPIRING_INBOX_NASHVILLE,
  stl: INSPIRING_INBOX_STL,
  baltimore: INSPIRING_INBOX_BALTIMORE,
  raleigh: INSPIRING_INBOX_RALEIGH,
  sanantonio: INSPIRING_INBOX_SANANTONIO,
  savannah: INSPIRING_INBOX_SAVANNAH,
  jacksonville: INSPIRING_INBOX_JACKSONVILLE,
  michigan: INSPIRING_INBOX_MICHIGAN,
  ohio: INSPIRING_INBOX_OHIO,
  memphis: INSPIRING_INBOX_MEMPHIS,
  utah: INSPIRING_INBOX_UTAH,
  southcarolina: INSPIRING_INBOX_SOUTHCAROLINA,
  knoxville: INSPIRING_INBOX_KNOXVILLE,
  minnesota: INSPIRING_INBOX_MINNESOTA,
  indiana: INSPIRING_INBOX_INDIANA,
};

export const SHOUTOUT_INBOX_LIST = {
  "sid@voyagela.com": "Sid",
  "stephanie@voyagela.com": "Stephanie",
  "mike@voyagela.com": "Mike",
  "kris@voyagela.com": "Kris",
  "sarah@voyagela.com": "Sarah",
  "alex@voyagela.com": "Alex",
  "chris@voyagela.com": "Chris",
  "jess@voyagela.com": "Jess",
  "ravinah@voyagela.com": "Ravinah",
  "pat@sdvoyager.com": "Pat",
  "stephanie@sdvoyager.com": "Stephanie",
  "emily@sdvoyager.com": "Emily",
  "mary@sdvoyager.com": "Mary",
  "jessica@sdvoyager.com": "Jessica",
  "ravinah@voyagemia.com": "Ravinah",
  "erika@voyagemia.com": "Erika",
  "dan@voyagemia.com": "Dan",
  "emma@voyagemia.com": "Emma",
  "jessica@voyagemia.com": "jessica",
  "sarah@voyagemia.com": "Sarah",
  "eric@voyagemia.com": "Eric",
  "kenneth@voyageatl.com": "Kenneth",
  "mike@voyageatl.com": "Mike",
  "shelly@voyageatl.com": "Shelley",
  "dianne@voyageatl.com": "Dianne",
  "sofia@voyageatl.com": "Sofia",
  "erika@voyageatl.com": "Erika",
  "olivia@voyageatl.com": "Olivia",
  "angela@voyagehouston.com": "Angela",
  "iris@voyagehouston.com": "Iris",
  "michelle@voyagehouston.com": "Michelle",
  "leo@voyagehouston.com": "Leo",
  "arianna@voyagehouston.com": "Ariana",
  "ashley@voyagehouston.com": "Ashley",
  "jonathan@voyagedallas.com": "Jonathan",
  "kristen@voyagedallas.com": "Kristen",
  "charles@voyagedallas.com": "Charles",
  "christian@voyagedallas.com": "Christian",
  "eric@voyagedallas.com": "Eric",
  "camila@voyagedallas.com": "Camila",
  "emily@voyagedallas.com": "Emily",
  "jon@voyagephoenix.com": "Jon",
  "nick@voyagephoenix.com": "Nick",
  "alex@voyagephoenix.com": "Alex",
  "rob@voyagephoenix.com": "Rob",
  "megan@voyagephoenix.com": "Megan",
  "nick@voyagedenver.com": "Nick",
  "sarah@voyagedenver.com": "Sarah",
  "kim@voyagedenver.com": "Kim",
  "ashley@voyagedenver.com": "Ashley",
};

export const SHOUTOUT_INBOX_LA = [
  "alex@voyagela.com",
  "chris@voyagela.com",
  "jess@voyagela.com",
  "kevin@voyagela.com",
  "kris@voyagela.com",
  "mike@voyagela.com",
  "ravinah@voyagela.com",
  "sarah@voyagela.com",
  "sid@voyagela.com",
  "stephanie@voyagela.com",
];

export const SHOUTOUT_INBOX_SD = [
  "emily@sdvoyager.com",
  "jessica@sdvoyager.com",
  "mary@sdvoyager.com",
  "pat@sdvoyager.com",
  "stephanie@sdvoyager.com",
];

export const SHOUTOUT_INBOX_ATLANTA = [
  "kenneth@voyageatl.com",
  "mike@voyageatl.com",
  "shelly@voyageatl.com",
  "dianne@voyageatl.com",
  "sofia@voyageatl.com",
  "erika@voyageatl.com",
  "olivia@voyageatl.com",
];

export const SHOUTOUT_INBOX_DALLAS = [
  "jonathan@voyagedallas.com",
  "kristen@voyagedallas.com",
  "charles@voyagedallas.com",
  "christian@voyagedallas.com",
  "camila@voyagedallas.com",
  "emily@voyagedallas.com",
  "eric@voyagedallas.com",
];

export const SHOUTOUT_INBOX_HOUSTON = [
  "angela@voyagehouston.com",
  "iris@voyagehouston.com",
  "michelle@voyagehouston.com",
  "leo@voyagehouston.com",
  "arianna@voyagehouston.com",
  "ashley@voyagehouston.com",
];

export const SHOUTOUT_INBOX_MIAMI = [
  "ravinah@voyagemia.com",
  "erika@voyagemia.com",
  "dan@voyagemia.com",
  "emma@voyagemia.com",
  "jessica@voyagemia.com",
  "sarah@voyagemia.com",
  "eric@voyagemia.com",
];

export const SHOUTOUT_INBOX_PHOENIX = [
  "jon@voyagephoenix.com",
  "nick@voyagephoenix.com",
  "alex@voyagephoenix.com",
  "rob@voyagephoenix.com",
  "megan@voyagephoenix.com",
];

export const SHOUTOUT_INBOX_DENVER = ["nick@voyagedenver.com", "sarah@voyagedenver.com", "kim@voyagedenver.com", "ashley@voyagedenver.com"];

export const SHOUTOUT_CITY_INBOX_MAP = {
  la: SHOUTOUT_INBOX_LA,
  sd: SHOUTOUT_INBOX_SD,
  atl: SHOUTOUT_INBOX_ATLANTA,
  dallas: SHOUTOUT_INBOX_DALLAS,
  houston: SHOUTOUT_INBOX_HOUSTON,
  miami: SHOUTOUT_INBOX_MIAMI,
  phoenix: SHOUTOUT_INBOX_PHOENIX,
  denver: SHOUTOUT_INBOX_DENVER,
};

export const CR_INBOX_LIST = [
  "aaron@canvasrebel.com",
  "amy@canvasrebel.com",
  "angela@canvasrebel.com",
  "ashley@canvasrebel.com",
  "camila@canvasrebel.com",
  "chris@canvasrebel.com",
  "christina@canvasrebel.com",
  "dan@canvasrebel.com",
  "david@canvasrebel.com",
  "eric@canvasrebel.com",
  "gabriel@canvasrebel.com",
  "james@canvasrebel.com",
  "jennifer@canvasrebel.com",
  "jenny@canvasrebel.com",
  "jess@canvasrebel.com",
  "jessica@canvasrebel.com",
  "jordan@canvasrebel.com",
  "kristen@canvasrebel.com",
  "kristi@canvasrebel.com",
  "michael@canvasrebel.com",
  "mike@canvasrebel.com",
  "miranda@canvasrebel.com",
  "samantha@canvasrebel.com",
  "sarah@canvasrebel.com",
];

export const CITYNATIONAL_INBOX_LIST = [
  "aaron@canvasrebel.com",
  "amy@canvasrebel.com",
  "angela@canvasrebel.com",
  "ashley@canvasrebel.com",
  "camila@canvasrebel.com",
  "chris@canvasrebel.com",
  "christina@canvasrebel.com",
  "dan@canvasrebel.com",
  "david@canvasrebel.com",
  "eric@canvasrebel.com",
  "emilyt@canvasrebel.com",
  "gabriel@canvasrebel.com",
  "james@canvasrebel.com",
  "jenn@canvasrebel.com",
  "jennifer@canvasrebel.com",
  "jenny@canvasrebel.com",
  "jess@canvasrebel.com",
  "jessica@canvasrebel.com",
  "jordan@canvasrebel.com",
  "kristen@canvasrebel.com",
  "kristi@canvasrebel.com",
  "mark@canvasrebel.com",
  "michael@canvasrebel.com",
  "mike@canvasrebel.com",
  "miranda@canvasrebel.com",
  "ravi@canvasrebel.com",
  "samantha@canvasrebel.com",
  "sarah@canvasrebel.com",
];

export const BOLDJOURNEY_INBOX_LIST = [
  "alex@boldjourney.com",
  "amit@boldjourney.com",
  "andres@boldjourney.com",
  "anita@boldjourney.com",
  "anna@boldjourney.com",
  "anthony@boldjourney.com",
  "ashley@boldjourney.com",
  "ashley@voyagedenver.com",
  "ashley@voyagehouston.com",
  "camila@knoxvillevoyager.com",
  "camila@voyagejacksonville.com",
  "carmen@boldjourney.com",
  "david@orlandovoyager.com",
  "david@voyageatl.com",
  "david@voyageaustin.com",
  "david@voyagedallas.com",
  "david@voyagetampa.com",
  "eric@voyagedallas.com",
  "eric@voyagedenver.com",
  "eric@voyagemia.com",
  "jennifer@boldjourney.com",
  "jess@voyagela.com",
  "jessica@memphisvoyager.com",
  "jessica@voyagemia.com",
  "jon@voyagephoenix.com",
  "kris@voyagela.com",
  "leo@voyagehouston.com",
  "madison@boldjourney.com",
  "matt@boldjourney.com",
  "megan@boldjourney.com",
  "michael@boldjourney.com",
  "mike@boldjourney.com",
  "mike@voyageatl.com",
  "mike@voyagela.com",
  "megan@voyagephoenix.com",
  "nadia@boldjourney.com",
  "natalie@boldjourney.com",
  "neil@boldjourney.com",
  "nina@boldjourney.com",
  "olivia@boldjourney.com",
  "rahul@boldjourney.com",
  "ravi@boldjourney.com",
  "pat@sdvoyager.com",
  "sarah@boldjourney.com",
  "sarah@voyageatl.com",
  "sarah@voyagedenver.com",
  "shelly@voyageatl.com",
  "sofia@voyageatl.com",
  "stephanie@sdvoyager.com",
  "tanya@boldjourney.com",
]

export const TEAM_USER_TYPE = {
  INSPIRING: "inspiring",
  SHOUTOUT: "shoutout",
  CR: "cr",
  BOLDJOURNEY: "boldjourney",
  LEADGEN: "leadgen",
  ADMIN: "admin",
};