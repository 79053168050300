import React, { Component } from 'react';
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';

export default class ConfirmPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false, currentNote: "" };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setNotes = this.setNotes.bind(this);
  }
  openModal() {
    this.setState({ open: true });
    this.setState({ currentNote: this.props.defaultValue });
  }
  closeModal() {
    this.setState({ open: false });
  }

  setNotes(event){
     this.setState({currentNote: event.target.value});
  }

  render() {
    return (
      <span>
        <button style={{backgroundColor: "rgb(31, 139, 210)", color: "white", height: 30, fontSize: 15, cursor: "pointer", ...this.props.style}} onClick={this.openModal}>
          {this.props.buttonText}
        </button>
        <Popup
          open={this.state.open}
          onClose={this.closeModal}
          closeOnDocumentClick={false}
        >
          <div className="modal" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
           <div className="header"> {this.props.displayText}</div>
           <div className="actions">
             <button
               className=""
               style={{backgroundColor: "rgb(31, 139, 210)", color: "white", width: 100, height: 30, fontSize: 15, marginRight: 20}}
               onClick={() => {
                 this.props.confirmMethod();
                 this.closeModal();
               }}
             >
               Yes
             </button>
             <button
               className=""
               style={{backgroundColor: "rgb(31, 139, 210)", color: "white", width: 100, height: 30, fontSize: 15}}
               onClick={() => {
                 this.closeModal();
               }}
             >
               No
             </button>
           </div>
          </div>
        </Popup>
      </span>
    );
  }
}