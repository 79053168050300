import React from 'react'
import axios from "axios";
import Select from 'react-select';
import {getServerUrl,PRODUCTION_SERVER_URL} from "../../constants";
import {Helmet} from "react-helmet";

export default class ReferenceForm extends React.Component {

  constructor() {
     super();
     this.state = {
      name: "",
      email: "",
      website: "",
      socialMedia: "",
      userName: "",
      isSubmitted: false,
     }

     this.handleEmailChange = this.handleEmailChange.bind(this);
     this.onSubmitForm = this.onSubmitForm.bind(this);
     this.showForm = this.showForm.bind(this);
  }

  showForm() {
    this.setState({isSubmitted: false});
  }

  onSubmitForm() {
     this.setState({isSubmitted: true});
     const { email } = this.state;
     if (email !== "") {
          let data = {};
          data.email = email;
          data.isArchived = false;
          axios
         .post(`${getServerUrl()}/api/siteEmailUsers/add`, data)
         .then(res => {
            
         })
         .catch(err => {
         })
     }
     this.setState({email: ""});
  }


  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  render() {
    const self = this;
    return (
        <div className="App Full-Width Flex">
          <Helmet>
              <title>Referrals</title>
          </Helmet>
          <div className="Main" >
            <div className="Content" style={{alignItems: "center"}}>
               <div style={{display: this.state.isSubmitted ? "none" : 'block'}}>
                 <div className="ContentStyle Font Line-Height">We started in Los Angeles, with our flagship publication <span > <a style={{textDecoration: "none", color: "#1f8bd2"}} href="http://voyagela.com/" target="_blank">
                           VoyageLA
                  </a></span>. After generating our first million page views in LA we knew our content was resonating with the community.</div>
                 <div className="ContentStyle Font Line-Height">We’ve since grown to a handful of other amazing cities with the help of an amazing network of friends, associates, local insiders and influencers, PR firms, local bloggers, artists, creatives, entrepreneurs and other professionals. </div>
                 <div className="ContentStyle Font Line-Height">We're working hard to learn more about the incredible people that bring so much heart, soul and creativity to our cities. From artists and creatives to mom-and-pops and rising entrepreneurs, our stories help foster a stronger, more collaborative and connected community.</div>
                 <div className="ContentStyle Font Line-Height Bold">However, we understand that not everyone is interested in being interviewed or sharing their story. If you would like for us to not reach out to you in the future, you can let us know below and we'll be sure not to email you again. Either way - we wish you and your business the absolute best!</div>
                 <form className="Form Left-Align" onSubmit={this.handleSubmit}>
                   
                    <div className="Font-Size-16 Font Bold" style={{marginTop: 9, marginBottom: 9, color: "#625b5d"}}>Enter your email here:</div>
                    <input className="Full-Width Input Font" style={{marginBottom: 9}} type="text" value={this.state.email} onChange={this.handleEmailChange}></input>
                     <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                        <div className="FormButton Next" style={{color: '#fff', backgroundColor: "#1f8bd2" }} onClick={this.onSubmitForm}>Submit</div>
                     </div>              
                 </form>
               </div>
            
               <div className="Font Line-Height" style={{display: this.state.isSubmitted ? 'block' : 'none' }} > Thank you for letting us know, your preferences have been successfully updated. We wish you the best. Have a great day!
               </div> 
            </div>
          </div>
          
        </div>

    )
  }
}