import apiFn, { ADMIN_AUTH_TOKEN_NAME, AUTH_TYPE, IS_ADMIN, PRINT_ADMIN_AUTH_TOKEN_NAME, TEAM_AUTH_TOKEN_NAME, TEAM_USER_CITY, TEAM_USER_EMAIL, TEAM_USER_TYPE } from "./api-service";
import { removeSpace } from "../../utils/helperFunctions";

const apiAdmin = (path) => apiFn(`/api/adminUsers/${path}`, AUTH_TYPE.PUBLIC);

export const loginAdmin = (email, password) => {
    const data = { email: removeSpace(email), password: removeSpace(password) };
    return apiAdmin('login')
        .post('', data)
        .then(res => {
            const { token } = res.data;
            if(email=="prints@voyage.com"){
                localStorage.setItem(PRINT_ADMIN_AUTH_TOKEN_NAME, token);

            }
            else{

                localStorage.setItem(ADMIN_AUTH_TOKEN_NAME, token);
            }
            localStorage.setItem(IS_ADMIN, true);
            return res.data;
        });
}

export const logoutAdmin = () => {
    localStorage.removeItem(ADMIN_AUTH_TOKEN_NAME);
    localStorage.removeItem(IS_ADMIN);
    window.location.reload();
}


export const logoutAdminPrints = () => {
    localStorage.removeItem(PRINT_ADMIN_AUTH_TOKEN_NAME);
    window.location.reload();
}
export const registerAdmin = (email, password) => {
    const data = { email: removeSpace(email), password: removeSpace(password) };
    apiAdmin('register')
        .post('', data)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem(ADMIN_AUTH_TOKEN_NAME, token);
        });
}

export const loginTeamUser = (email, password, allowedTypes) => {
    const data = { email: removeSpace(email), password: removeSpace(password), allowedTypes };
    return apiFn(`/api/team-user/login`)
        .post('', data)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem(TEAM_AUTH_TOKEN_NAME, token);
            localStorage.setItem(TEAM_USER_TYPE, res.data.teamUser.userType);
            localStorage.setItem(TEAM_USER_CITY, res.data.teamUser.city);
            localStorage.setItem(TEAM_USER_EMAIL, res.data.teamUser.email);
            return res.data;
        });
}

export const logoutTeamUser = () => {
    localStorage.removeItem(TEAM_AUTH_TOKEN_NAME);
    localStorage.removeItem(TEAM_USER_TYPE);
    localStorage.removeItem(TEAM_USER_CITY);
    localStorage.removeItem(TEAM_USER_EMAIL);
    window.location.reload();
}

export const registerTeamUser = (email, password, userType) => {
    const data = { email: removeSpace(email), password: removeSpace(password), userType };
    apiFn("/api/team-user/register")
        .post('', data)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem(TEAM_AUTH_TOKEN_NAME, token);
        });
}