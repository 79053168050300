import styled from 'styled-components';

export const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const Button = styled.button`
    width: 100px;
    height: 50px;
    background-color: ${props => props.active === true ? 'lightblue' : 'white'};
    margin-right: 20px;

    &:hover {
        cursor: pointer;
        background-color: lightblue;
    }
`;

export const ActionButton = styled.button`
    background-color: ${props => props.disabled ? 'grey' : 'rgb(31, 139, 210)'} ;
    color: white;
    font-size: 15px;
    cursor: pointer;
    margin: 10px;
    margin-left: 0;
    width: ${props => props.width ? `${props.width}px` : '120px'};
    height: ${props => props.height ? `${props.height}px` : '30px'};
`;
