import axios from "axios";
import React from 'react';
import { Helmet } from "react-helmet";
import {  getServerUrl } from '../../constants';
import { getFullDate, removeSpace } from '../../utils/helperFunctions.js';
import ConfirmPopup from "../Popups/ConfirmPopup";

export default class RecommendationAdmin extends React.Component {

  constructor() {
    super();
    this.state = {
      siteRefferals: [],
      archivedSiteRefferals: [],
      email: "",
      password: "",
      selected: "Users",
      errorMessageSigningIn: ""
    }
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.signInUser = this.signInUser.bind(this);
    this.signOutUser = this.signOutUser.bind(this);
  }

  getAuthHeader() {
    return { headers: { Authorization: `${localStorage.getItem("inspiringAppAdminToken")}` } };
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  signOutUser() {
    localStorage.removeItem("inspiringAppAdminToken");
    this.setState({ userSignedIn: false });
  }

  signInUser() {
    const self = this;
    if (this.state.email.length === 0) {
      this.setState({ errorMessageSigningIn: "You have to enter email to continue" });
      return;
    }
    else if (this.state.password.length === 0) {
      this.setState({ errorMessageSigningIn: "You have to enter password to continue" });
      return;
    }

    if (removeSpace(this.state.email) !== "admin@voyage.com") {
      this.setState({ errorMessageSigningIn: "Wrong email" });
      return;
    }

    const userData = { email: removeSpace(this.state.email), password: removeSpace(this.state.password) };
    axios
      .post(`${getServerUrl()}/api/adminUsers/login`, userData)
      .then(res => {

        const { token } = res.data;
        localStorage.setItem("inspiringAppAdminToken", token);
        self.setState({ email: "" });
        self.setState({ password: "" });
        self.setState({ errorMessageSigningIn: "" });
        self.setState({ userSignedIn: true });
        self.fetchData();
      })
      .catch(err => {
        console.log(err);
        self.setState({ errorMessageSigningIn: "Wrong username and password" });
      })
  };

  fetchData() {
    axios
      .get(`${getServerUrl()}/api/siteEmailUsers/getAllUsers`, this.getAuthHeader())
      .then(res => {
        this.setState({ siteRefferals: res.data });
      })
  }

  componentDidMount() {
    if (localStorage.getItem("inspiringAppAdminToken")) {
      const self = this;
      this.setState({ userSignedIn: true });
      this.fetchData();
    }
  }

  render() {
    const siteRefferals = this.state.siteRefferals.length > 0;
    return (
      <div className="" style={{ marginLeft: 15, height: "100%", overflow: "scroll" }}>
        <Helmet>
          <title>Recommendations</title>
        </Helmet>
        <div className="" style={{ display: this.state.userSignedIn ? 'none' : 'block', }}>Sign In</div>
        <div style={{ display: this.state.userSignedIn ? 'none' : 'block' }} >Email</div>
        <input className="Input" style={{ display: this.state.userSignedIn ? 'none' : 'block' }} type="text" value={this.state.email} onChange={this.handleEmailChange}></input>
        <div style={{ display: this.state.userSignedIn ? 'none' : 'block' }}>Password</div>

        <input className="Input" style={{ display: this.state.userSignedIn ? 'none' : 'block' }} type="text" value={this.state.password} onChange={this.handlePasswordChange}></input>
        <button style={{ display: this.state.userSignedIn ? 'none' : 'block', width: 100, height: 30 }} onClick={this.signInUser}>Sign In</button>
        <div style={{ display: this.state.errorMessageSigningIn ? 'block' : 'none' }} >{this.state.errorMessageSigningIn}</div>
        <div style={{ display: this.state.userSignedIn ? 'block' : 'none', width: 100, height: 30 }}>Signed In</div>
        <button style={{ display: this.state.userSignedIn ? 'block' : 'none', width: 100, height: 30 }} onClick={this.signOutUser}>Sign Out</button>

        <div style={{ marginBottom: 7, display: this.state.userSignedIn && this.state.selected === "Create" && this.state.normalState ? 'block' : 'none', }} >New User Email</div>

        <input style={{ marginBottom: 7, display: this.state.userSignedIn && this.state.selected === "Create" && this.state.normalState ? 'block' : 'none', }} className="Input" type="text" value={this.state.newUserEmail} onChange={this.handleNewUserEmailChange}></input>


        {this.state.userSignedIn && <div style={{ marginTop: 20, fontWeight: "bold" }}>Site Emails</div>}

        {siteRefferals && this.state.userSignedIn &&
          <div style={{ marginBottom: 10, marginTop: 10 }}>{`Total number: ${this.state.siteRefferals.length}`} </div>
        }
        {siteRefferals && this.state.userSignedIn &&

          <table>
            <thead>
              <tr>
                <th className="TextAlignCenter" style={{ width: 100, minWidth: 100, fontWeight: "bold", border: "1px solid" }}>Number</th>
                <th className="TextAlignCenter" style={{ width: 500, minWidth: 500, border: "1px solid" }}>Email</th>
              </tr>

            </thead>
            <tbody>
              {this.state.siteRefferals.map((item, index) => (
                <tr key={index}>
                  <td className="TextAlignCenter Height50" style={{ width: 100, minWidth: 100, border: "1px solid" }} >{`${index + 1}`}</td>
                  <td className="TextAlignCenter Height50" style={{ width: 500, minWidth: 500, border: "1px solid" }}>{item.email}</td>
                </tr>
              ))}
            </tbody>
          </table>

        }

      </div>

    )
  }
}
