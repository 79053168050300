import axios from "axios";
import { getServerUrl } from "../Admin/constants.js";

export const ADMIN_AUTH_TOKEN_NAME = "inspiringAppAdminToken";
export const PRINT_ADMIN_AUTH_TOKEN_NAME = "inspiringPrintsAppAdminToken";
export const TEAM_AUTH_TOKEN_NAME = "teamUserToken";
export const TEAM_USER_TYPE = "team-user-type";
export const TEAM_USER_EMAIL = "team-user-email";
export const TEAM_USER_CITY = "team-user-city";
export const IS_ADMIN = "isAdmin";

export const AUTH_TYPE = {
  PUBLIC: "public",
  ADMIN: "admin",
  TEAM: "team",
};

const apiFn = (path, authType = AUTH_TYPE.ADMIN) => {
  let headers;
  switch (authType) {
    case AUTH_TYPE.ADMIN:
      headers = {
        Authorization: `${localStorage.getItem(ADMIN_AUTH_TOKEN_NAME)}`,
      };
      break;
    case AUTH_TYPE.TEAM:
      headers = {
        Authorization: `${localStorage.getItem(TEAM_AUTH_TOKEN_NAME)}`,
      };
      break;
    case AUTH_TYPE.PUBLIC:
      headers = {};
      break;
    default:
      throw new Error("Invalid auth type");
  }
  const api = axios.create({
    baseURL: `${getServerUrl()}${path}`,
    headers,
  });

  return api;
};

export default apiFn;
