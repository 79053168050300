import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import NotFound from './NotFound.js';
import {  TEAM_AUTH_TOKEN_NAME, TEAM_USER_TYPE } from '../Admin/api-service.js';
import Login from '../Admin/Login.js';
import RecommendationForm from "../Forms/RecommendationForm.js"
import RecommendationAdmin from "../Admin/RecommendationAdmin.js"

const TeamUserRoute = ({ children, allowedTypes, ...rest }) => {
  const isAuthenticated = localStorage.getItem(TEAM_AUTH_TOKEN_NAME);
  const userType = localStorage.getItem(TEAM_USER_TYPE);
  const next = rest?.location?.pathname;
  const search = next ? `?next=${next}` : '';

  return (
    <Route {...rest} >
      {isAuthenticated && allowedTypes.includes(userType)
        ? children
        : <Redirect to={{
          pathname: `/admin/login`,
          search,
          state: { allowedTypes },
        }} />}
    </Route>
  );
}
class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/admin/login' exact render={(props) => <Login {...props} loginType='admin' />} />
          <Route path="/admin/preferences" component={RecommendationAdmin} />
          <Route path="/preferences" component={RecommendationForm} />
          <Route path="*" component={NotFound} />
        </Switch>

      </BrowserRouter>

    )
  }
}

export default App;
