import React, { useState } from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { useHistory, useLocation } from "react-router-dom";

import { Button } from './Components/Button';
import { loginAdmin as loginAdminFn, loginTeamUser as loginTeamUserFn } from './user-service';
import { Helmet } from "react-helmet";
import { ADMIN, BOLDJOURNEY_APP, CR_APP, INSPIRING_APP, SHOUTOUT_APP } from './constants';

const Body = styled.div`
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    align-items: center;
    height: calc(100% - 200px);
`;

const Input = styled.input`
    width: 300px;
    height: 30px;
`;

const Select = styled(ReactSelect)`
    width: 300px;
    height: 30px;
`

export const MODE_SIGN_IN = 'sign-in';
export const MODE_SIGN_UP = 'sign-up';

const USER_TYPES = [
    { value: INSPIRING_APP, label: 'Inspiring' },
    { value: SHOUTOUT_APP, label: 'Shoutout' },
    { value: CR_APP, label: 'CR' },
    { value: BOLDJOURNEY_APP, label: 'BoldJourney' },
]

const Login = ({ helmet, allowSignUp, error, onSignIn, onSignUp, loginType }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userType, setUserType] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [loginError, setLoginError] = useState('');
    const history = useHistory();
    const { search, state } = useLocation();

    const handleSignIn = async () => {
        setLoginError('');
        if (!email || !password) {
            setLoginError('Email and password are required');
            return;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setLoginError('Invalid email');
            return;
        }

        if (!onSignIn) {
            try {
                if (loginType === ADMIN) {
                    await loginAdminFn(email, password);
                    const query = new URLSearchParams(search);
                    const next = query.get('next');
                    history.replace(next);
                } else {
                    const { allowedTypes } = state;
                    await loginTeamUserFn(email, password, allowedTypes);
                    const query = new URLSearchParams(search);
                    const next = query.get("next");
                    history.replace(next);
                }
            } catch (error) {
                if (error.response.status === 404) {
                    setLoginError(error.response.data.message || 'Invalid email or password');
                } else {
                    setLoginError('Something went wrong');
                }
            } finally {
                return;
            }
        }

        onSignIn(email.trim(), password);
    }

    return <Body>
        {helmet && <Helmet>
            <title>{helmet}</title>
        </Helmet>}
        <Input
            type="text"
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
        />
        <Input
            type="password"
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
        />
        {(loginError || error) && <div
            style={{ color: 'red' }}
        >
            {loginError && loginError}
            {error && error}
        </div>}
        {isSignUp && <>
            <Input
                type="password"
                onChange={e => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
            />
            <Select
                name='userType'
                onChange={e => setUserType(e.value)}
                options={[...USER_TYPES]}
            />
        </>}
        <div>
            {!isSignUp && <Button onClick={handleSignIn}>Sign in</Button>}
            {allowSignUp &&
                <>
                    {!isSignUp && <Button onClick={() => setIsSignUp(true)}>Sign up</Button>}
                    {isSignUp &&
                        <Button onClick={async () => {
                            if (password !== confirmPassword) {
                                throw new Error('Passwords do not match');
                            }
                            if (!USER_TYPES.some(x => x.value === userType)) {
                                throw new Error('User type must be selected');
                            }
                            await onSignUp(email, password, userType);
                            setEmail('');
                            setPassword('');
                            setConfirmPassword('');
                            setUserType('');
                            setIsSignUp(false);
                        }}
                        >
                            Register
                        </Button>
                    }
                    {isSignUp && <Button onClick={() => setIsSignUp(false)}>Cancel</Button>}
                </>
            }
        </div>
    </Body>
}

export default Login;