export const SERVER_URL = "http://localhost:5000";

export const PRODUCTION_SERVER_URL = window.location.origin;

export const TEST_PRODUCTION_SERVER_URL = "";

export const getServerUrl = () => {
  let serverUrl = SERVER_URL;
  
  if (process.env.NODE_ENV === "production") {
    serverUrl = PRODUCTION_SERVER_URL;
  }
  
  return serverUrl;
};
