import React from 'react';
import moment from 'moment';
import axios from "axios";

export const getRandomNumber = (length) => {
    const chars = "123456789";
    let pass = "";
    for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }

    return parseInt(pass);
}

export const getEmailPart = (email) => {
  if (!email) {
    return ""
  }
  const partBeforeAt = email.split("@")[0];
  return partBeforeAt;
}

export const areEmailsSame = (email1, email2) => {
  // Normalize both emails
  const normalizedEmail1 = email1?.trim().toLowerCase();
  const normalizedEmail2 = email2?.trim().toLowerCase();

  return normalizedEmail1 === normalizedEmail2;
}

export const checkPhraseInParagraph = (paragraph, phrase) => {
  // Convert the paragraph to lower case to ensure the check is case-insensitive
  const lowerCaseParagraph = paragraph.toLowerCase();

  // Check if the exact sequence 'do not use' is in the paragraph
  return lowerCaseParagraph.includes(phrase);
}

// Need to pass date objects
export const getDateDiff = (date1, date2) => {
  return (date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24 // diff in days
}

export const getArrayFromArrayOfObjects = (array, item) =>  {
    const result = [];

    array.forEach(obj => {
        result.push(obj[item]);
    });

    return result;
}

export const parseEmail = (text) => {
  if (!text) {
    return "";
  }
  text = text.toString()
  // Regular expression to match email addresses
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  // Use match() method to find all the matches in the text
  let emails = text.match(emailRegex);
  // if (!!emails && emails.length > 1) {
  //     emails = emails.join(", ")
  // }

  // Return the array of emails or an empty array if no match is found
  return emails && emails.length > 0 ? emails[0] : "";
}

export const parseIG = (textOrUrl) => {
  if (!textOrUrl) {
    return "";
  }
  textOrUrl = textOrUrl.toString()
  // Regular expression to match Instagram URL or "IG: handle" format
  const instagramPattern = /(?:https?:\/\/www\.instagram\.com\/([^/?]+))|(?:IG:\s*([A-Za-z0-9_.]+))/;

  // Try to find a match for an Instagram pattern
  const match = textOrUrl.match(instagramPattern);

  // Extract username if match is found
  const username = match ? (match[1] || match[2]) : null;

  return username;
}

//generating random number between lowerLimit and upperLimit (including lower limit and upper limit)- https://www.codecademy.com/forum_questions/5198adbdbbeddf9726000700
// Genrate randowm number between 0 and 2 would be generateRandomNumberBetweenNumbers(0,2) // results in either 0,1 ,2
export const generateRandomNumberBetweenNumbers = (lowerLimit, upperLimit) => {
      return Math.floor((Math.random() * ((upperLimit - lowerLimit) + 1)) + lowerLimit);
  }

  export const isEmptyObject = (obj) =>  {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
  }

export const validateEmail = (email) => {
  var re = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  return re.test(String(email).toLowerCase());
}

export const getFullDate = (dateObject) => {
    if (!dateObject) {
      return "";
    }

    //var dateObj = new Date(dateObject);
    var dateObj = moment(new Date(dateObject)).utc().toDate();
    //console.log(dateObject, dateObj);
    return (dateObj.getMonth() + 1) + "/" + dateObj.getDate() + "/" + dateObj.getFullYear();
}

export const getFullDateMonthName = (dateObject) => {
    if (!dateObject) {
      return "";
    }

    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
    ];


    var dateObj = new Date(dateObject);
    return monthNames[dateObj.getMonth()] + "_" + dateObj.getDate() + "_" + dateObj.getFullYear();
}

export const ErrorComponent = (props) => {
    const { errorValues } = props;

      return (
        <div className="Error-Container">
          {errorValues.map((item, index) => 
             <div className="Error-Item" key={index}>{item}</div>
          )}
        </div>
      );

    return null;
}


export const removeSpecialCharacters = (input) => {
   let string = input.replace(/[^a-zA-Z ]/g, "");
   return string;
}

export const removeSpace = (input) => {
   let string = input.replace(/\s/g, '');
   return string;
}

const listOfPrankWords = ["coitus", "ass", "dick", "pussy", "nigga", "nigger", "bitch", "titty", "shitty", "retard", "idiot", "bot", 
"robot", "scam", "clyde", "slut", "whore", "fuck", "cunt", "chink", "wetback", "beaner", "porn", "minors", "sex", "penetration", "penis", "vag", "vagina", "clit", 
"clitoris", "xxx", "fag", "prank", "voyage", "voyage media", "voyagela", "voyageatl", "voyagedallas", "voyagedenver", 
"voyagehouston", "voyagemia","voyagephoenix", "sdvoyager", "bostonvoyager", "voyagechicago", "voyage boston", "voyagemiami", "voyageatlanta", "fake", "exploit", "fart", "masturbate", 
"masterbate", "ejaculate", "ejaculation", "orgasm", "cum", "kum", "pornography", "fucker", "fucking", "fucked",
 "motherfucker", "motherfuckers", "deeznuts", "bukaki", "bukakke", "flatulence", "poop", "poopy", "pooper", "but-hole", "butt-hole", "butthole", 
 "benchode", "chode", "matherchode", "chutiya", "chut", "ligma", "winyard", "bletch", "n****s", "n***a", "n*****", "n*******", "n****r", "n*****s"];

const listOfPrankPhrases = ["voyage atl", "voyage dallas", "voyage denver","don’t even read", "don’t read", "didn’t read", "boston voyager", "voyage chicago", "muff diver", "deez nuts",
"butt hole", "muff diver", "voyage atlanta", "eskimo bro", "eskimo brothers", "eskimo brother", "san diego voyager","voyage phoenix", "voyage miami", 
"voyagemiami", "voyage mia", "voyage houston", "pant wetter", "sd voyager", "voyage mia", "voyage miami", "voyage houston"]

export const isPrank = (input) => {
    let isPrank = false;

    if (!input) {
      return false;
    }

    for (let m = 0; m < listOfPrankPhrases.length; m++) {
      let currentPhraseToCheck = listOfPrankPhrases[m];
      if (checkPhraseInParagraph(input, currentPhraseToCheck)) {
         isPrank = true;
         break;
      }
    }

    if (isPrank) {
      return isPrank;
    }

    let firstSplit = input.replace(/\n|↵/g, ' '); // replace newline with spaces
    let secondSplit = firstSplit.split('.').join(' '); // replace periods with spaces
    let thirdSplit = secondSplit.split(' ').join(',');  //relace spaces with commas
    let fourthSplit = thirdSplit.split(",");
    for (let i = 0; i < fourthSplit.length; i++) {
        let currentWord = fourthSplit[i].toLowerCase();
        for (let m = 0; m < listOfPrankWords.length; m++) {
            let currentWordToCheck = listOfPrankWords[m];
            if (currentWord === currentWordToCheck) {
               isPrank = true;
               break;
            }
        }
      }

    return isPrank;
}

export const getPrankWords = (input) => {
   let prankWords = []

   if (!input) {
     return false;
   }

   for (let m = 0; m < listOfPrankPhrases.length; m++) {
    let currentPhraseToCheck = listOfPrankPhrases[m];
    if (checkPhraseInParagraph(input, currentPhraseToCheck)) {
      prankWords.push(currentPhraseToCheck);
      break;
    }
  }

  let firstSplit = input.replace(/\n|↵/g, ' '); // replace newline with spaces
  let secondSplit = firstSplit.split('.').join(' '); // replace periods with spaces
  let thirdSplit = secondSplit.split(' ').join(',');  //relace spaces with commas
  let fourthSplit = thirdSplit.split(",");
  for (let i = 0; i < fourthSplit.length; i++) {
      let currentWord = fourthSplit[i].toLowerCase();
      for (let m = 0; m < listOfPrankWords.length; m++) {
          let currentWordToCheck = listOfPrankWords[m];
          if (currentWord === currentWordToCheck) {
            prankWords.push(currentWord);
             break;
          }
      }
    }
  
   return prankWords;
}

const listOfNoteWords = ["edit", "editing", "editor", "grammar", "grammatical", "grammartic", "grammer-check", "review", "spelling", "spell", 
"spell-check", "note",  "proofread", "proof-read", "publish", "publishing"];

const listOfNotePhrases = ["give me a call","let me know", "proof read", "cut down", 
"spell check", "feel free", "call me", "email me"];

export const isNotes = (input) => {
    let isNotes = false;

    if (!input) {
      return false;
    }

    for (let m = 0; m < listOfNotePhrases.length; m++) {
      let currentPhraseToCheck = listOfNotePhrases[m];
      if (checkPhraseInParagraph(input, currentPhraseToCheck)) {
        isNotes = true;
         break;
      }
    }

    if (isNotes) {
      return isNotes;
    }

    let firstSplit = input.replace(/\n|↵/g, ' '); // replace newline with spaces
    let secondSplit = firstSplit.split('.').join(' '); // replace periods with spaces
    let thirdSplit = secondSplit.split(' ').join(',');  //relace spaces with commas
    let fourthSplit = thirdSplit.split(",");
    for (let i = 0; i < fourthSplit.length; i++) {
        let currentWord = fourthSplit[i].toLowerCase();
        for (let m = 0; m < listOfNoteWords.length; m++) {
            let currentWordToCheck = listOfNoteWords[m];
            if (currentWord === currentWordToCheck) {
               isNotes = true;
               break;
            }
        }
    }

    return isNotes;
}

export const getNoteWords = (input) => {
    let noteWords = [];

    if (!input) {
      return false;
    }

    for (let m = 0; m < listOfNotePhrases.length; m++) {
      let currentPhraseToCheck = listOfNotePhrases[m];
      if (checkPhraseInParagraph(input, currentPhraseToCheck)) {
        noteWords.push(currentPhraseToCheck)
        break;
      }
    }

    let firstSplit = input.replace(/\n|↵/g, ' '); // replace newline with spaces
    let secondSplit = firstSplit.split('.').join(' '); // replace periods with spaces
    let thirdSplit = secondSplit.split(' ').join(',');  //relace spaces with commas
    let fourthSplit = thirdSplit.split(",");
    for (let i = 0; i < fourthSplit.length; i++) {
        let currentWord = fourthSplit[i].toLowerCase();
        for (let m = 0; m < listOfNoteWords.length; m++) {
            let currentWordToCheck = listOfNoteWords[m];
            if (currentWord === currentWordToCheck) {
              noteWords.push(currentWord)
              break;
            }
        }
    }
    return noteWords;
}

const getFileNameFromHeader = (contentDispositionHeader, defaultName) => {
  const match = contentDispositionHeader.split('filename=')[1].split(';')[0];

  if (match) {
    return match;
  }

  return defaultName;
}

export const capitalizeFirstLetter = (word) => {
  if (!word) return '';

  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const handleDownload = (url) => {
  axios
    .get(url, {
      responseType: "blob",
      headers: { Authorization: `${localStorage.getItem("inspiringAppAdminToken")}`},
    })
    .then((response) => {
      const fileName = getFileNameFromHeader(response.headers["content-disposition"], "file.zip");
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
}